// userInfo对象包含appKey,token
export default function init(userInfo, callbacks) {
	if (!userInfo.appKey || !userInfo.token) {
		return false;
	}
	// console.log(userInfo.appKey,"===lmxuhwagl6v0d")
	// 非 ESModule
	const RongIMLib = require('@rongcloud/imlib-v4')
	//公有云初始化
	const RongIMClient = RongIMLib.init({
		appkey: userInfo.appKey
	});
	// var instance = RongIMClient.getInstance();
	//连接状态监听器
	RongIMClient.connect({
		token: userInfo.token
	}).then(user => {
		// console.log('链接成功, 链接用户 id 为: ', user.id);
		window.localStorage.setItem("rongyunId", user.id)
	}).catch(error => {
		console.log('链接失败: ', error.code, error.msg);
		switch (error.code) {
			case 31002:
				console.log("appKey错误");
				break;
			case 31003:
				console.log("服务器错误");
				break;
			case 31004:
				console.log("token无效");
				break;
			case 31005:
				console.log("appKey和token不匹配");
				break;
			case 31006:
				console.log("链接重定向");
				break;
			case 31008:
				console.log("AppKey 被封禁或被删除");
				break;
			case 31009:
				console.log("用户被封禁");
				break;
			case 31012:
				console.log("安全域名错误, 请至开发者后台查看设置的安全域名");
				break;
			case 34001:
				console.log("连接已存在, 请勿重复链接");
				break;
			case 35007:
				console.log("调用 reconnect() 前需先调用 disconnect() 方法");
				break;
		}
	});
	return RongIMClient
}