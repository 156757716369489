<template>
  <div class="index">
    <!-- 组件 -->
    <!-- 侧边栏组件 -->
    <slider-bar />
    <!-- 路由组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
import sliderBar from "../../components/slider-bar/slider-bar.vue";
export default {
  components: { sliderBar },
};
</script>

<style scoped>
.index {
  background: rgb(255, 255, 255);
  display: flex;
  height: 100%;
  width: 100%;
  /* width: 1000px; */
}
</style>